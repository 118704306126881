import styled from 'styled-components';
//import { themeGet } from '@styled-system/theme-get';

const VisitBlockWrapper = styled.div`
  text-align: center;
  position: relative;
`;

export const VisitBlockImageArea = styled.div`
  padding: 30px 0 60px 0;

  @media (min-width: 576px) {}
  @media (min-width: 768px) {}
  @media (min-width: 991px) {}
  @media (min-width: 1220px) {}
`;

export default VisitBlockWrapper;
