import React from 'react';
import PropTypes from 'prop-types';

// Components
// =============================================================================
import Image from 'common/src/components/Image';
import Button from 'common/src/components/Button';

// Styles
// =============================================================================
import VisitBlockWrapper, { VisitBlockImageArea } from './VisitBlock.style';

// Colours
// =============================================================================
//import brand from 'common/src/theme/landing/brand';

// VisitBlock
// =============================================================================
const VisitBlock = ({
  VisitBlockLogoStyle,
  VisitBlockImageStyle,
  VisitBlockButtonStyle,
  visitLogo,
  visitImage,
}) => {
  const handleOpenWebsite = () => {
    window.open('https://cybertonica.com?ref=discover', '_blank');
  };

  return (
    <VisitBlockWrapper>
      <Image src={visitLogo} {...VisitBlockLogoStyle} alt="Logo" />
      <VisitBlockImageArea>
        <Image src={visitImage} {...VisitBlockImageStyle} alt="Logo" />
      </VisitBlockImageArea>
      <Button
        title="Visit our Website"
        onClick={handleOpenWebsite}
        {...VisitBlockButtonStyle}
      />
    </VisitBlockWrapper>
  );
};

VisitBlock.propTypes = {
  VisitBlockLogoStyle: PropTypes.object,
  VisitBlockImageStyle: PropTypes.object,
  VisitBlockButtonStyle: PropTypes.object,
};

VisitBlock.defaultProps = {
  VisitBlockLogoStyle: {
    maxWidth: '300px',
    width: '100%',
    m: '0 auto 30px',
  },
  VisitBlockImageStyle: {
    maxWidth: '150px',
    width: '100%',
    m: '0 auto',
    display: 'inline-block',
  },
  VisitBlockButtonStyle: {
    type: 'button',
    fontSize: ['15px', '16px'],
    fontWeight: '500',
    colors: 'cyber5WithBg',
    minHeight: '40px',
    pt: '0',
    pb: '0',
    pl: ['15px', '20px'],
    pr: ['15px', '20px'],
  },
};

export default VisitBlock;
