import styled from 'styled-components';
//import { themeGet } from '@styled-system/theme-get';

const SectionAreaWrapper = styled.div`
  padding: 60px 26px;
  width: 100%;
  max-width: 500px;
  margin: 0 auto;

  @media (min-width: 991px) {
    max-width: 700px;
  }

  @media (min-width: 1220px) {
    padding: 40px 60px;
  }
`;

export const SectionArea = styled.div`
  position: relative;

  @media (min-width: 320px) {
  }
  @media (min-width: 576px) {
  }
  @media (min-width: 768px) {
  }
  @media (min-width: 991px) {
  }
  @media (min-width: 1220px) {
  }
`;

export default SectionAreaWrapper;
