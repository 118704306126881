import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import { landingTheme } from 'common/src/theme/landing';
import { ResetCSS } from 'common/src/assets/css/style';
import { useStaticQuery, graphql } from 'gatsby';
import CookieConsent from 'react-cookie-consent';

// Sections
import VisitWithForm from '../containers/Landing/sections/VisitWithForm';
import StaticFooter from '../containers/Landing/sections/StaticFooter';

// Styles
import {
  GlobalStyle,
  ContentWrapper,
} from '../containers/Landing/landing.style';

// Components
import SEO from '../components/seo';

// Images
// =============================================================================
import MainLogo from 'common/src/assets/image/cybertonica-logo.png';
import IconLogo from 'common/src/assets/image/logo.png';
import AppLogo from 'common/src/assets/image/eComm360shopify/app-logo.png';

// Smooth Scroll
if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]');
}

export default () => {
  const DataQuery = useStaticQuery(graphql`
    query {
      discoverJson {
        Main {
          SEO {
            seoTitle
            seoDesc
          }
          StaticFooter {
            footerAbout
            dataAwards {
              content
            }
          }
        }
      }
    }
  `);

  // Data
  const Data = DataQuery.discoverJson.Main;

  // StaticFooter
  const footerAbout = Data.StaticFooter.footerAbout;
  const dataAwards = Data.StaticFooter.dataAwards;

  return (
    <ThemeProvider theme={landingTheme}>
      <Fragment>
        <SEO title="Discover" description="" />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <VisitWithForm
            visitLogo={MainLogo}
            visitImage={IconLogo}
            formHeading="Sign up for our Newsletter"
            formLogo={AppLogo}
            formButton="Subscribe"
            formLink="/discover"
            formPageType="Main"
          />
          <StaticFooter footerAbout={footerAbout} dataAwards={dataAwards} />
          <CookieConsent
            location="bottom"
            buttonText="Accept"
            style={{ background: '#2b3d4d' }}
            buttonStyle={{
              color: '#2b3d4d',
              background: '#e6ff0f',
              fontSize: '13px',
              borderRadius: '4px',
            }}
            cookieName="gatsby-plugin-gdpr-cookies"
          >
            We are using cookies to give you the best experience on our website.
            <br />
            You can find out more about which cookies we are using or switch
            them off in settings. To find out more, see our
            <a
              href="https://cybertonica.com/privacy-policy?ref=discover"
              className="DefaultPrivacyLink"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
            .
          </CookieConsent>
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
