import React from 'react';
import PropTypes from 'prop-types';

// UI
// =============================================================================
import Container from 'common/src/components/UI/Container';

// Components
// =============================================================================
import Box from 'common/src/components/Box';

// Blocks
// =============================================================================
import PromoBlock from './blocks/Visit';
import FormBlock from './blocks/Form';

// Styles
// =============================================================================
import SectionAreaWrapper, { SectionArea } from './VisitWithForm.style';

// Colours
// =============================================================================
import brand from 'common/src/theme/landing/brand';

// VisitWithForm
// =============================================================================
const VisitWithForm = ({
  SectionWrapperStyle,
  RowStyle,
  ColStyle,
  ColOneStyle,
  ColTwoStyle,
  visitLogo,
  visitImage,
  formHeading,
  formButton,
  formLink,
  formPageType,
}) => {
  return (
    <Box {...SectionWrapperStyle} id="visit_form">
      <Container fullWidth noGutter>
        <Box {...RowStyle}>
          <Box {...ColStyle} {...ColOneStyle}>
            <SectionAreaWrapper>
              <SectionArea>
                <PromoBlock
                  visitLogo={visitLogo}
                  visitImage={visitImage}
                />
              </SectionArea>
            </SectionAreaWrapper>
          </Box>
          <Box {...ColStyle} {...ColTwoStyle}>
            <SectionAreaWrapper>
              <SectionArea>
                <FormBlock
                  formHeading={formHeading}
                  formButton={formButton}
                  formLink={formLink}
                  formPageType={formPageType}
                />
              </SectionArea>
            </SectionAreaWrapper>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

VisitWithForm.propTypes = {
  SectionWrapperStyle: PropTypes.object,
  RowStyle: PropTypes.object,
  ColStyle: PropTypes.object,
  ColOneStyle: PropTypes.object,
  ColTwoStyle: PropTypes.object,
};

VisitWithForm.defaultProps = {
  SectionWrapperStyle: {
    as: 'section',
    bg: brand.brand8,
    pt: 0,
    pb: 0,
  },
  RowStyle: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'top',
    justifyContent: 'center',
    height: ['auto', 'auto', 'auto', '740px', '760px'],
  },
  ColStyle: {
    width: ['100%', '100%', '100%', '50%', '50%'],
    height: '100%',
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  ColOneStyle: {},
  ColTwoStyle: {
    bg: brand.brand8Tint90,
  },
};

export default VisitWithForm;
