import React from 'react';

// Common
// =============================================================================
import MailChimpForm from '../../../../common/MailChimpForm';

// FormBlock
// =============================================================================
const FormBlock = ({
  formHeading,
  formLogo,
  formButton,
  formLink,
  formPageType,
}) => {
  return (
    <MailChimpForm
      formHeading={formHeading}
      formLogo={formLogo}
      formButton={formButton}
      formLink={formLink}
      formPageType={formPageType}
    />
  );
};

export default FormBlock;
